.scroll {
	position: fixed;
	cursor: pointer;
	right: 20px;
	bottom: 20px;
	width: 70px;
	height: 70px;
	background-image: url('../../images/top.png');
	background-repeat: no-repeat;
	background-position: center;
	transition: all 0.3s ease;

	&:hover {
		opacity: 0.8;
	}
}