@import '../../../styles/variables.scss';

.wrapper {
	display: grid;
	grid-template-rows: min-content 1fr min-content;
	margin: 0 auto;
	min-height: 100vh;
	grid-template-columns: 100%;
	min-width: 375px;

	@media (max-width: $mobile-width) {
		font-size: 15px;
	}
}

.image {
	position: relative;
	height: 600px;
	background-image: linear-gradient(to top, rgba(56, 40, 40, 0.3), rgba(56, 40, 40, 0.3)), url('../../../images/kista-zuba-1.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	z-index: 0;

	@media (max-width: $mobile-width) {
		height: 400px;
	}
}

.main_text {
	padding: 30px 70px;
	background-color: #f3f6f4;
	position: relative;
	border-radius: 15px;
	margin-top: -200px;
	z-index: 1;

	@media (max-width: $tablet-width) {
		padding: 10px 40px;
	}
}

.usluga {
	padding-top: 30px;
	padding-left: 176px;
	padding-right: 176px;
	padding-bottom: 50px;

	@media (max-width: $desktop-width) {
		padding-left: 44px;
		padding-right: 44px;
	}

	@media (max-width: $tablet-width) {
		padding-left: 24px;
		padding-right: 24px;
	}
}

.main_title {
	font-family: $roboto-bold;
	line-height: 35px;

	@media (max-width: $tablet-width) {
		font-size: 24px;
	}

	@media (max-width: $mobile-width) {
		font-size: 20px;
		line-height: 30px;
	}
}

.title {
	font-family: $roboto-bold;

	@media (max-width: $mobile-width) {
		line-height: 30px;
	}
}

.title_image {
	font-family: $roboto-bold;
	font-size: 24px;
	line-height: 30px;
	letter-spacing: 1px;
	color: $white;
	text-align: center;
	margin-top: 150px;
	margin-bottom: 150px;

	@media (max-width: $tablet-width) {
		margin-top: 100px;
		margin-bottom: 100px;
	}
}

.bold_text {
	font-family: $roboto-bold;
}

.wrapper_desc {
	display: grid;
	grid-template-areas:
		'image text';
	grid-template-columns: auto 1fr;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 30px;

	&_revers {
		grid-template-areas:
			'text image';
		grid-template-columns: 1fr auto;

		@media (max-width: $mobile-width) {
			grid-template-areas:
				'text'
				'image';
			grid-template-columns: 1fr;
			margin-bottom: 0;
		}
	}

	@media (max-width: $mobile-width) {
		grid-template-areas:
			'image'
			'text';
		grid-template-columns: 1fr;
		margin-bottom: 0;
	}
}

.wrapper_image {
	grid-area: image;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 500px;
	min-width: 500px;
	height: auto;
	margin: 0 auto;
	margin-right: 50px;
	background-image: linear-gradient(to top, rgba(56, 40, 40, 0.6), rgba(56, 40, 40, 0.6)), url('../../../images/kista-zuba.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	padding: 0 30px;

	@media (max-width: $tablet-width) {
		margin-right: 30px;
		max-width: 400px;
		min-width: 350px;
	}

	@media (max-width: $mobile-width) {
		margin-right: 0;
		width: 100%;
		max-width: 100%;
		min-width: 100%;
		margin: 0 auto;
	}

	&_revers {
		margin-right: 0;
		margin-left: 50px;

		@media (max-width: $tablet-width) {
			margin-left: 30px;
		}

		@media (max-width: $mobile-width) {
			margin-left: 0;
			margin: 0 auto;
		}
	}
}

.wrapper_text {
	grid-area: text;
}

.link {
	text-decoration: none;
	color: inherit;
	font-family: $roboto-bold;
	transition: all 0.3s ease;

	&:hover {
		opacity: 0.5;
	}
}