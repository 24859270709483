@import '../../styles/variables.scss';

.wrapper {
	background-color: $gray;
}

.promo {
	padding-left: 176px;
	padding-right: 176px;
	padding-top: 50px;
	padding-bottom: 100px;

	@media (max-width: $desktop-width) {
		padding-left: 44px;
		padding-right: 44px;
		padding-top: 30px;
		padding-bottom: 70px;
	}

	@media (max-width: $tablet-width) {
		padding-top: 10px;
		padding-bottom: 40px;
		padding-left: 24px;
		padding-right: 24px;
	}
}

.title {
	font-family: $inter-bold;
	font-size: 48px;
	line-height: 48px;
	text-transform: uppercase;
	text-align: center;

	@media (max-width: $desktop-width) {
		font-size: 40px;
		line-height: 40px;
	}

	@media (max-width: $tablet-width) {
		font-size: 35px;
		line-height: 35px;
	}

	@media (max-width: $mobile-width) {
		font-size: 28px;
		line-height: 28px;
	}
}

.list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.item {
	margin-bottom: 30px;

	&:last-child {
		margin-bottom: 0;
	}
}

.container {
	background-color: $white;
	border-radius: 15px;
	padding: 36px;
	display: grid;
	grid-template-columns: auto 1fr;
	transition: all 0.3s ease;

	&:hover {
		box-shadow: 0px 0px 10px 6px rgba(34, 60, 80, 0.1);
	}

	&_border {
		box-shadow: 0px 0px 5px 2px rgba(34, 60, 80, 0.1);
	}

	@media (max-width: $tablet-width) {
		padding: 15px;
	}

	@media (max-width: $mobile-width) {
		grid-template-columns: 1fr;
		justify-items: center;
	}
}

.image {
	width: 500px;
	height: auto;
	padding-right: 10px;
	box-sizing: content-box;

	@media (max-width: $desktop-width) {
		width: 400px;
	}

	@media (max-width: $tablet-width) {
		width: 300px;
		align-self: center;
	}

	@media (max-width: $mobile-width) {
		margin-bottom: 20px;
	}
}

.description {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (max-width: $mobile-width) {
		text-align: center;
		max-width: 350px;
	}
}

.sub_title {
	font-family: $inter-bold;
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 0;

	@media (max-width: $tablet-width) {
		font-size: 18px;
		line-height: 22px;
		margin-top: 0;
		margin-bottom: 10px;
	}
}

.text {
	margin: 0;

	@media (max-width: $tablet-width) {
		font-size: 14px;
		line-height: 20px;
	}
}

.sub_text {
	margin: 0;
	opacity: 0.5;
	font-size: 14px;
	line-height: 14px;
}

.button {
	text-transform: uppercase;
	text-decoration: none;
	padding: 12px 20px;
	border-radius: 20px;
	display: inline-block;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	width: 160px;
	text-align: center;
	margin-bottom: 15px;
	box-sizing: content-box;
	transition: all 0.3s ease;
	background-color: $red;
	color: $white;
	border: 2px solid $red;

	&:hover {
		background-color: $white;
		color: $red;
	}

	@media (max-width: $tablet-width) {
		font-size: 14px;
		line-height: 16px;
		width: 120px;
		margin-bottom: 0;
		padding: 8px 10px;
		align-self: center;
	}

	@media (max-width: $mobile-width) {
		margin-top: 15px;
		margin-bottom: 5px;
	}
}