@import '../../styles/variables.scss';

.prices {
	padding-left: 176px;
	padding-right: 176px;
	padding-bottom: 50px;

	@media (max-width: $desktop-width) {
		padding-left: 44px;
		padding-right: 44px;
	}

	@media (max-width: $tablet-width) {
		padding-left: 24px;
		padding-right: 24px;
	}

	@media (max-width: $mobile-width) {
		padding-left: 5px;
		padding-right: 5px;
	}
}

.title {
	font-size: 40px;
	line-height: 40px;
	font-family: $inter-bold;
	color: $blue-title;
	text-align: center;

	@media (max-width: $tablet-width) {
		font-size: 28px;
		line-height: 30px;
	}
}

.subtitle {
	font-size: 30px;
	line-height: 30px;
	font-family: $inter-bold;
	color: $blue-title;
	text-align: center;

	@media (max-width: $tablet-width) {
		font-size: 20px;
		line-height: 20px;
		margin-top: -10px;
	}
}

.table {
	width: 100%;
	border-collapse: collapse;

	@media (max-width: $tablet-width) {
		font-size: 14px;
	}
}

.table tr {
	background-color: #f3f6f4;
	transition: all 0.3s ease;
}

.table tr:nth-child(2n) {
	background-color: $white;
}

.table tr:hover {
	background-color: #d7ecce;
}

.table td {
	padding: 10px;

	@media (max-width: $mobile-width) {
		padding: 5px 8px;
	}
}

.table td:first-child {
	width: 6%;
}

.table td:last-child {
	width: 22%;
	text-align: center;
}

.text {
	font-family: $inter-bold;
	text-align: center;
	padding-right: 100px;
	padding-left: 100px;

	@media (max-width: $mobile-width) {
		padding-right: 20px;
		padding-left: 20px;
	}
}