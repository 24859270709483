@import '../../styles/variables.scss';

.wrapper {
	background-color: $gray;
}

.gallery {
	padding-left: 176px;
	padding-right: 176px;
	padding-top: 50px;
	padding-bottom: 100px;
	max-width: 1522px;
	width: 100%;
	margin: 0 auto;

	@media (max-width: $desktop-width) {
		padding-left: 44px;
		padding-right: 44px;
		padding-top: 30px;
		padding-bottom: 70px;
		width: 936px;
		box-sizing: content-box;
	}

	@media (max-width: $tablet-width) {
		padding-top: 10px;
		padding-bottom: 40px;
		padding-left: 24px;
		padding-right: 24px;
		width: 696px;
	}

	@media (max-width: $mobile-width) {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}

.title {
	font-family: $inter-bold;
	font-size: 48px;
	line-height: 48px;
	text-transform: uppercase;
	text-align: center;

	@media (max-width: $desktop-width) {
		font-size: 40px;
		line-height: 40px;
	}

	@media (max-width: $tablet-width) {
		font-size: 35px;
		line-height: 35px;
	}

	@media (max-width: $mobile-width) {
		font-size: 28px;
		line-height: 28px;
	}
}

.photos {
	margin-top: 50px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

	@media (max-width: $tablet-width) {
		margin-top: 30px
	}

	@media (max-width: $mobile-width) {
		display: none;
	}
}

.item {
	margin-bottom: 30px;

	@media (max-width: $tablet-width) {
		margin-bottom: 13px;
	}

	&:nth-child(3n+1) {
		justify-self: start;
	}

	&:nth-child(3n+2) {
		justify-self: center;
	}

	&:nth-child(3n+3) {
		justify-self: end;
	}
}

.image {
	border-radius: 15px;
	display: block;
	width: 370px;
	height: auto;

	@media (max-width: $desktop-width) {
		width: 290px;
	}

	@media (max-width: $tablet-width) {
		width: 223px;
	}

	&_mobile {
		@media (max-width: $mobile-width) {
			width: 100%;
			height: auto;
		}
	}
}