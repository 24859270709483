.button {
	text-transform: uppercase;
	text-decoration: none;
	padding: 12px 20px;
	border-radius: 20px;
	display: inline-block;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	width: 160px;
	text-align: center;
	margin-bottom: 15px;
	box-sizing: content-box;
	transition: all 0.3s ease;

	@media (max-width: $tablet-width) {
		font-size: 16px;
		line-height: 16px;
		width: 140px;
		margin-bottom: 0;
	}

	@media (max-width: $mobile-width) {
		font-size: 14px;
		line-height: 16px;
		width: 120px;
		padding: 8px 10px;
	}

	&_light {
		background-color: $white;
		color: $red;
		border: 2px solid $white;

		&:hover {
			border: 2px solid $red;
		}
	}

	&_dark {
		background-color: $red;
		color: $white;
		border: 2px solid $red;

		&:hover {
			background-color: $white;
			color: $red;
		}
	}
}