@import '../../styles/variables.scss';

.wrapper {
	display: grid;
	grid-template-rows: min-content 1fr min-content;
	margin: 0 auto;
	min-height: 100vh;
	grid-template-columns: 100%;
	min-width: 375px;
}

.order_bg {
	background-image: url('../../images/order-bg.png');
	background-repeat: no-repeat;
	background-position: 90% 20%;
	background-size: cover;
}

.orders {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 176px;
	padding-right: 176px;
	padding-bottom: 50px;
	padding-top: 50px;
	transition: all 0.3s ease;

	@media (max-width: $desktop-width) {
		padding-left: 44px;
		padding-right: 44px;
	}

	@media (max-width: $tablet-width) {
		padding-left: 24px;
		padding-right: 24px;
	}

	@media (max-width: $mobile-width) {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.text {
	margin-top: 0;
	margin-bottom: 10px;
	text-align: center;
}

.send {
	position: relative;
	margin-top: 40px;
}

.form {
	display: flex;
	flex-direction: column;
	width: 500px;

	@media (max-width: $mobile-width) {
		width: 350px;
	}
}

.labels {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.label {
	flex-grow: 1;

	@media (max-width: $mobile-width) {
		font-size: 14px;
	}
}

.label_input {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.answer {
	@media (max-width: $mobile-width) {
		font-size: 14px;
	}
}

.input {
	border: none;
	background: $white;
	padding: 15px;
	width: 60%;

	@media (max-width: $mobile-width) {
		width: 55%;
	}

	&_date {
		width: 72%;
	}
}

.input_radio {
	margin: 0;
	margin-left: 10px;
	width: 23px;
}

.textarea {
	border: none;
	background: $white;
	padding: 15px;
	width: 73%;
}

.captcha {
	margin-top: 10px;
	margin-bottom: 10px;
}

.error {
	color: $red;
	margin: 0;
	margin-bottom: 5px;
	font-size: 14px;

	&_none {
		display: none;
	}
}

.success {
	position: absolute;
	top: 38%;
	left: 50%;
	transform: translateX(-50%);
	background-color: #198754;
	color: $white;
	padding: 30px;
	width: 300px;
	text-align: center;
	border-radius: 10px;

	&_none {
		display: none;
	}
}

.button_form {
	display: flex;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 30px;
	font-family: inherit;
	font-size: 18px;
	background: $white;
	border: 2px solid #808080;
	border-radius: 10px;
	transition: all 0.3s ease;
	padding: 10px 30px;
	cursor: pointer;
	color: inherit;

	&:hover {
		background: #808080;
		color: $white;
	}

	@media (max-width: $mobile-width) {
		font-size: 16px;
	}
}

.link {
	display: block;
	margin: 0 auto;
	text-decoration: none;
	color: inherit;
	transition: all 0.3s ease;
	margin-bottom: 10px;

	&:hover {
		opacity: 0.8;
	}
}