@import '../../styles/variables.scss';

.wrapper {
	width: 1170px;
	margin: 0 auto;
	margin-top: 42px;
	display: grid;
	grid-template-columns: 300px 1fr 1fr 1fr;
	min-width: 375px;

	@media (max-width: $desktop-width) {
		width: 100%;
		padding-left: 44px;
		padding-right: 44px;
	}

	@media (max-width: $tablet-width) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto 1fr;
		margin-top: 22px;
		padding-left: 24px;
		padding-right: 24px;
	}

	@media (max-width: $mobile-width) {
		grid-template-columns: 1fr;
	}
}

.logo {
	@media (max-width: $tablet-width) {
		grid-column: 1 / 4;
		justify-self: center;
		margin-bottom: 25px;
	}

	@media (max-width: $mobile-width) {
		grid-column: 1 / 2;
	}
}

.place {
	margin-top: 20px;
	margin-left: 30px;
}

.container {
	justify-self: end;

	@media (max-width: $tablet-width) {
		justify-self: center;
	}

	@media (max-width: $mobile-width) {
		text-align: center;
		margin-bottom: 20px;
	}

	&:last-child {
		padding-right: 50px;

		@media (max-width: $tablet-width) {
			padding-right: 0;
			margin-left: auto;
		}

		@media (max-width: $mobile-width) {
			margin-right: auto;
		}
	}

	&:nth-child(2) {
		@media (max-width: $tablet-width) {
			margin-right: auto;
		}

		@media (max-width: $mobile-width) {
			margin-left: auto;
		}
	}
}

.title {
	font-weight: 600;
	font-size: 24px;
	line-height: 24px;
	margin: 0;

	@media (max-width: $tablet-width) {
		font-size: 18px;
		line-height: 24px;
	}
}

.text {
	&_red {
		color: $red;
	}

	@media (max-width: $tablet-width) {
		font-size: 14px;
		line-height: 30px;
		margin: 0;
	}
}

.link {
	position: relative;
	display: block;
	margin: 16px 0;
	text-decoration: none;
	color: $black;
	transition: all 0.3s ease;

	@media (max-width: $tablet-width) {
		font-size: 14px;
		line-height: 30px;
		margin: 0;
	}

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 20px;
		height: 20px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	&:hover {
		opacity: 0.7;
	}
}

.vk {
	padding-left: 25px;

	&::before {
		background-image: url('../../images/vk.png');
	}
}

.instagram {
	padding-left: 25px;

	&::before {
		background-image: url('../../images/instagram.png');
	}
}

.whatsapp {
	padding-left: 25px;

	&::before {
		background-image: url('../../images/whatsapp.png');
	}
}

.telegram {
	padding-left: 25px;

	&::before {
		background-image: url('../../images/telegram.png');
	}
}

.copyright {
	text-align: center;
	opacity: 0.5;
	font-size: 12px;
	line-height: 18px;
}

.warning {
	text-align: center;
	opacity: 0.5;
	font-size: 26px;
	text-transform: uppercase;
	line-height: 28px;

	@media (max-width: $tablet-width) {
		font-size: 22px;
		line-height: 24px;
	}

	@media (max-width: $mobile-width) {
		font-size: 14px;
		line-height: 18px;
	}
}