@import '../../styles/variables.scss';

.department {
	display: flex;
	margin-bottom: 70px;
	width: 100%;
	margin: 0 auto;

	@media (max-width: $tablet-width) {
		flex-direction: column;
	}
}

.section {
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;

	&_left {
		margin-right: 15px;
		background-image: url('../../images/banner-1-dant-plus.jpg');

		@media (max-width: $tablet-width) {
			margin-right: 0;
			margin-bottom: 24px;
		}

		@media (max-width: $mobile-width) {
			margin-bottom: 10px;
		}
	}

	&_right {
		background-image: url('../../images/banner-2-dant-plus.jpg');
		background-position: 30% 50%;
	}

	@media (max-width: $mobile-width) {
		min-width: 375px;
	}
}

.section_wrapper {
	display: flex;
	flex-direction: column;
	width: 434px;
	color: $white;

	@media (max-width: $tablet-width) {
		width: 400px;
	}

	@media (max-width: $mobile-width) {
		width: 100%;
	}

	&_right {
		text-align: right;
		margin-left: 144px;
		margin-right: auto;
		align-items: flex-end;
		padding-bottom: 48px;

		@media (max-width: $desktop-width) {
			margin-left: auto;
			margin-right: 40px;
		}

		@media (max-width: $tablet-width) {
			margin: 0 auto;
			text-align: center;
			align-items: stretch;
			padding-bottom: 45px;
		}

		@media (max-width: $mobile-width) {
			padding-bottom: 30px;
		}
	}

	&_left {
		margin-right: 144px;
		margin-left: auto;
		align-items: flex-start;

		@media (max-width: $desktop-width) {
			margin-right: auto;
			margin-left: 40px;
		}

		@media (max-width: $tablet-width) {
			margin: 0 auto;
			text-align: center;
			align-items: stretch;
			padding-bottom: 45px;
		}

		@media (max-width: $mobile-width) {
			padding-bottom: 30px;
		}
	}
}

.title {
	font-family: $inter-bold;
	font-weight: 700;
	font-size: 48px;
	line-height: 67px;
	text-transform: uppercase;
	margin-top: 70px;
	margin-bottom: 0;

	@media (max-width: $tablet-width) {
		margin-top: 40px;
		font-size: 40px;
		line-height: 50px;
	}

	@media (max-width: $mobile-width) {
		margin-top: 30px;
		font-size: 30px;
		line-height: 40px;
	}
}

.text {
	font-size: 24px;
	line-height: 34px;

	&_right {
		padding-left: 100px;

		@media (max-width: $tablet-width) {
			padding-left: 0;
		}
	}

	@media (max-width: $tablet-width) {
		font-size: 18px;
		line-height: 34px;
		padding-right: 50px;
		padding-left: 50px;
	}

	@media (max-width: $mobile-width) {
		font-size: 16px;
		line-height: 25px;
	}
}

.pink {
	color: $pink-word;
}

.yellow {
	color: $yellow-word;
}

.green {
	color: $green-word;
}

.blue {
	color: $blue-word;
}

.red {
	color: $red-word;
}

.button_wrapper {
	display: flex;
	flex-direction: column;

	@media (max-width: $tablet-width) {
		flex-direction: row;
		justify-content: space-between;
	}

	@media (max-width: $mobile-width) {
		padding-right: 20px;
		padding-left: 20px;
	}
}