@import '../../styles/variables.scss';

.container {
	background-color: $white;
	border-radius: 15px;
	padding: 10px 36px;
	transition: all 0.3s ease;
	margin-bottom: 20px;
	box-shadow: 0px 0px 5px 2px rgba(34, 60, 80, 0.1);

	&:hover {
		box-shadow: 0px 0px 10px 6px rgba(34, 60, 80, 0.1);
	}

	@media (max-width: $tablet-width) {
		padding: 15px;
	}

	@media (max-width: $mobile-width) {
		padding: 5px 15px;
	}
}

.name {
	font-size: 20px;
	margin-bottom: 0;

	@media (max-width: $mobile-width) {
		font-size: 16px;
	}
}

.date {
	font-size: 14px;
	opacity: 0.8;

	@media (max-width: $mobile-width) {
		font-size: 12px;
		margin-top: 5px;
	}
}

.text {
	@media (max-width: $mobile-width) {
		font-size: 12px;
	}
}