@import '../../styles/variables.scss';

.licenses {
	padding-left: 176px;
	padding-right: 176px;
	padding-top: 50px;
	padding-bottom: 100px;

	@media (max-width: $desktop-width) {
		padding-left: 44px;
		padding-right: 44px;
		padding-top: 30px;
		padding-bottom: 70px;
	}

	@media (max-width: $tablet-width) {
		padding-top: 10px;
		padding-bottom: 40px;
		padding-left: 24px;
		padding-right: 24px;
	}
}

.title {
	font-family: $inter-bold;
	font-size: 48px;
	line-height: 48px;
	text-transform: uppercase;
	text-align: center;

	@media (max-width: $desktop-width) {
		font-size: 40px;
		line-height: 40px;
	}

	@media (max-width: $tablet-width) {
		font-size: 35px;
		line-height: 35px;
	}

	@media (max-width: $mobile-width) {
		font-size: 28px;
		line-height: 28px;
	}
}

.list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;

	@media (max-width: $desktop-width) {
		justify-content: space-between;
	}

	@media (max-width: $mobile-width) {
		flex-direction: column;
		align-items: center;
	}
}

.item {
	margin-right: 30px;

	&:last-child {
		margin-right: 0;
	}

	@media (max-width: $mobile-width) {
		margin-right: 0;
		margin-bottom: 10px;
	}
}

.image {
	box-shadow: 0px 0px 10px 6px rgba(34, 60, 80, 0.1);

	@media (max-width: $mobile-width) {
		width: 250px;
		height: auto;
		box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.1);
	}
}