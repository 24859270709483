@import '../../styles/variables.scss';

.wrapper {
	display: grid;
	grid-template-rows: min-content 1fr min-content;
	margin: 0 auto;
	min-height: 100vh;
	grid-template-columns: 100%;
	min-width: 375px;
}

.reviews {
	padding-left: 176px;
	padding-right: 176px;
	padding-bottom: 50px;
	padding-top: 50px;
	transition: all 0.3s ease;

	@media (max-width: $desktop-width) {
		padding-left: 44px;
		padding-right: 44px;
	}

	@media (max-width: $tablet-width) {
		padding-left: 24px;
		padding-right: 24px;
	}

	@media (max-width: $mobile-width) {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 20px;
	}
}

.title {
	font-family: $inter-bold;
}

.link {
	color: $blue-title;
	text-decoration: underline;
	cursor: pointer;
}

.qr {
	width: 300px;
	height: auto;
	margin: 0 auto;
}

.button {
	display: flex;
	margin: 0 auto;
	font-family: inherit;
	color: inherit;
	font-size: 18px;
	background: $gray;
	border: 2px solid $gray;
	border-radius: 10px;
	transition: all 0.3s ease;
	padding: 10px 30px;
	cursor: pointer;
	margin-bottom: 50px;

	&:hover {
		background: $white;
	}
}

.send {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	width: 500px;
	border-radius: 10px;
	background-color: $gray;

	@media (max-width: $mobile-width) {
		width: 100%;
		max-width: 400px;
		padding: 20px;
	}
}

.title {
	font-family: $inter-bold;
	font-weight: 700;
	font-size: 20px;
}

.form {
	@media (max-width: $mobile-width) {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
}

.labels {
	@media (max-width: $mobile-width) {
		width: 100%;
	}
}

.input {
	border: none;
	padding: 15px;
	width: 100%;
	font-size: 16px;
	margin-bottom: 20px;
}

.textarea {
	font-family: inherit;
	border: none;
	padding: 15px;
	width: 100%;
	font-size: 16px;
	margin-bottom: 20px;
}

.button_form {
	display: flex;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 30px;
	font-family: inherit;
	font-size: 18px;
	background: $white;
	border: 2px solid #808080;
	border-radius: 10px;
	transition: all 0.3s ease;
	padding: 10px 30px;
	cursor: pointer;
	color: inherit;

	@media (max-width: $mobile-width) {
		margin-bottom: 10px;
	}

	&:hover {
		background: #808080;
		color: $white;
	}
}

.captcha {
	@media (max-width: $mobile-width) {
		display: block;
		width: 100%;
		//transform: scale(0.95);
		transform-origin: 0 0;
	}
}

.success {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%);
	background-color: #198754;
	color: $white;
	padding: 30px;
	width: 300px;
	text-align: center;
	border-radius: 10px;

	&_none {
		display: none;
	}
}

.error {
	color: $red;
	margin: 0;
	margin-bottom: 5px;
	font-size: 14px;

	&_none {
		display: none;
	}
}

.shake {
	animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}

@keyframes shake {

	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}