@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'),
    local('Roboto Regular'),
    url('../fonts/roboto-regular.woff2') format('woff2'),
    url('../fonts/roboto-regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto'),
    local('Roboto Bold'),
    url('../fonts/roboto-bold.woff2') format('woff2'),
    url('../fonts/roboto-bold.woff') format('woff');
}

@font-face {
  font-family: 'Inter Regular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Inter'),
    local('Inter Regular'),
    url('../fonts/inter-regular.woff2') format('woff2'),
    url('../fonts/inter-regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Inter'),
    local('Inter Bold'),
    url('../fonts/inter-bold.woff2') format('woff2'),
    url('../fonts/inter-bold.woff') format('woff');
}

@font-face {
  font-family: 'Inter Big';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Inter'),
    local('Inter Big'),
    url('../fonts/inter-big-bold.woff2') format('woff2'),
    url('../fonts/inter-big-bold.woff') format('woff');
}

@font-face {
  font-family: 'Train One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Train One'),
    url('../fonts/train-one-regular.woff2') format('woff2'),
    url('../fonts/train-one-regular.woff') format('woff');
}

@font-face {
  font-family: 'Rampart One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Rampart One'),
    url('../fonts/rampart-one-regular.woff2') format('woff2'),
    url('../fonts/rampart-one-regular.woff') format('woff');
}

@font-face {
  font-family: 'Rubik Dirt';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik Dirt'),
    url('../fonts/rubik-dirt-regular.woff2') format('woff2'),
    url('../fonts/rubik-dirt-regular.woff') format('woff');
}

@font-face {
  font-family: 'Zen Kaku Regular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Zen Kaku'),
    local('Zen Kaku Regular'),
    url('../fonts/zen-kaku-gothic-regular.woff2') format('woff2'),
    url('../fonts/zen-kaku-gothic-regular.woff') format('woff');
}

@font-face {
  font-family: 'Zen Kaku Bold';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Zen Kaku'),
    local('Zen Kaku Bold'),
    url('../fonts/zen-kaku-gothic-900.woff2') format('woff2'),
    url('../fonts/zen-kaku-gothic-900.woff') format('woff');
}

@font-face {
  font-family: 'Rubik Moonrocks';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik Moonrocks'),
    url('../fonts/rubik-moonrocks-regular.woff2') format('woff2'),
    url('../fonts/rubik-moonrocks-regular.woff') format('woff');
}