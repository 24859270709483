$black: #202020;
$blue-title: #1B118F;
$white: #ffffff;
$transparent: rgba(0, 0, 0, 0);
$red: #EE2424;
$gray: #F8F8F8;
$blue-word: #00AEEF;
$green-word: #39CF42;
$pink-word: #F1008E;
$red-word: #F81A20;
$yellow-word: #FFF101;

/*font*/
$main-font: "Roboto Regular", "Arial", sans-serif;
$roboto-bold: "Roboto Bold", "Arial", sans-serif;
$inter-big: "Inter Big", "Arial", sans-serif;
$inter-bold: "Inter Bold", "Arial", sans-serif;
$inter-regular: "Inter Regular", "Arial", sans-serif;
$train-one-regular: "Train One", "Arial", sans-serif;
$rampart-one-regular: "Rampart One", "Arial", sans-serif;
$rubik-dirk: "Rubik Dirt", "Arial", sans-serif;
$zen-kaku-regular: "Zen Kaku Regular", "Arial", sans-serif;
$zen-kaku-bold: "Zen Kaku Bold", "Arial", sans-serif;
$rubik-moonrocks: "Rubik Moonrocks", "Arial", sans-serif;

/*viewports*/
$desktop-width: 1499px;
$tablet-width: 1023px;
$mobile-width: 743px;