@import '../../styles/variables.scss';

.header {
	min-width: 375px;
}

.header_top {
	display: grid;
	grid-template-columns: auto 1fr auto;
	align-items: center;
	max-width: 1522px;
	width: 100%;
	margin: 0 auto;

	@media (max-width: $desktop-width) {
		max-width: 1024px;
		margin: 0 auto;
		grid-template-columns: auto auto auto;
	}

	@media (max-width: $mobile-width) {
		grid-template-columns: 1fr 1fr;
	}
}

.logo_link {
	display: inline-block;

	@media (max-width: $desktop-width) {
		justify-self: center;

		@media (max-width: $mobile-width) {
			grid-row: 1 / 2;
		}
	}
}

.logo_image {
	display: block;

	@media (max-width: $desktop-width) {
		width: 95px;
		height: auto;
	}

	@media (max-width: $tablet-width) {
		width: 120px;
		height: auto;
	}

	@media (max-width: $mobile-width) {
		width: 100px;
		height: auto;
	}
}

.title {
	font-family: $inter-big;
	text-transform: uppercase;
	font-weight: 900;
	font-size: 48px;
	line-height: 40px;
	color: $blue-title;
	text-align: center;
	margin-top: 54px;
	margin-bottom: 41px;
	letter-spacing: 0.03em;

	@media (max-width: $desktop-width) {
		font-size: 36px;
		line-height: 36px;
	}

	@media (max-width: $tablet-width) {
		width: 460px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 30px;
		line-height: 45px;
	}

	@media (max-width: $mobile-width) {
		width: 300px;
		font-size: 28px;
		line-height: 32px;
		grid-row: 2 / 3;
		grid-column: 1 / 3;
		margin-top: 10px;
		margin-bottom: 20px;
	}
}

.contacts_wrapper {
	display: flex;
	justify-content: space-between;
	width: 900px;
	margin: 0 auto;
	background-color: $gray;
	padding: 24px;
	box-sizing: border-box;
	grid-row: 2 / 3;
	grid-column: 1 / 4;

	@media (max-width: $desktop-width) {
		width: 960px;
	}

	@media (max-width: $tablet-width) {
		width: 100%;
		padding: 15px;
		display: grid;
		grid-template-columns: 1fr auto 1fr;
	}
}

.contacts_phones {
	text-align: left;
}

.contacts_times {
	text-align: center;
}

.contacts_address {
	text-align: right;
}

.contacts_title {
	font-family: $inter-bold;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	margin: 0;
	margin-bottom: 13px;

	@media (max-width: $tablet-width) {
		font-size: 20px;
		line-height: 20px;
	}
}

.contacts_text {
	font-family: $roboto-bold;
	font-size: 18px;
	line-height: 21px;
	margin: 0;
	margin-bottom: 9px;

	&:last-child {
		margin-bottom: 0;
	}

	&_red {
		color: $red;
	}

	@media (max-width: $tablet-width) {
		font-family: $main-font;
		font-size: 16px;
		line-height: 21px;
	}
}

.contacts_phone {
	display: block;
	text-decoration: none;
	color: $black ;
	font-family: $inter-bold;
	font-size: 18px;
	line-height: 21px;
	margin-bottom: 9px;
	transition: all 0.3s ease;

	&:hover {
		opacity: 0.7;
	}

	@media (max-width: $tablet-width) {
		font-family: $main-font;
		font-size: 16px;
		line-height: 21px;

		&_menu {
			margin-bottom: 0;
		}
	}
}

.header_timer_wrapper {
	background-color: $black;
	color: $white;
	font-family: $inter-regular;
	font-size: 23px;
	line-height: 60px;
	height: 60px;

	@media (max-width: $tablet-width) {
		font-size: 18px;
		line-height: 50px;
		height: 50px;
	}

	@media (max-width: $mobile-width) {
		font-size: 16px;
		line-height: 40px;
		height: 40px;
	}
}

.header_timer {
	max-width: 890px;
	margin: 0 auto;

	@media (max-width: $tablet-width) {
		max-width: 700px;
	}

	@media (max-width: $mobile-width) {
		max-width: 340px;
	}
}

.nav_wrapper {
	background-color: $gray;

	@media (max-width: $mobile-width) {
		position: relative;
	}
}

.nav_list {
	display: flex;
	margin: 0;
	padding: 0;
	justify-content: center;
	list-style: none;

	@media (max-width: $mobile-width) {
		flex-direction: column;
		display: none;

		&_open {
			display: flex;
			position: absolute;
			top: 55px;
			background-color: $gray;
			width: 100%;
			border: none;
			z-index: 1111;
			box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.4);
		}
	}
}

.nav_link {
	text-decoration: none;
	font-size: 18px;
	line-height: 18px;
	color: $black;
	transition: all 0.3s ease;

	@media (max-width: $tablet-width) {
		font-size: 16px;
	}

	@media (max-width: $mobile-width) {
		line-height: 16px;
	}

	&:hover {
		opacity: 0.7;
	}
}

.nav_item {
	padding: 48px 32px;

	@media (max-width: $desktop-width) {
		padding: 32px 18px;
	}

	@media (max-width: $tablet-width) {
		padding: 20px 18px;
	}

	@media (max-width: $mobile-width) {
		padding: 13px 18px;
	}

	&_hidden {
		@media (max-width: $tablet-width) {
			display: none;
		}
	}

	&_tablet_hidden {
		display: none;

		@media (max-width: $mobile-width) {
			display: block;
			margin-bottom: 0;
			padding-bottom: 23px;
			background-color: #cfecf7;
		}
	}

	&_tablet_hidden_top {
		border-top: 1px solid $black;
	}
}

.hidden_tablet {
	@media (max-width: $tablet-width) {
		display: none;
	}
}

.hidden {
	@media (max-width: $mobile-width) {
		display: none;
	}
}

.menu_button {
	display: none;

	@media (max-width: $mobile-width) {
		display: block;
		border: none;
		background-color: $transparent;
		font-family: $main-font;
		font-size: 16px;
		line-height: 16px;
		padding: 20px 18px;
		text-align: center;
		margin: 0 auto;
		padding-right: 20px;
		position: relative;
		outline: none;
		color: $black;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			width: 14px;
			height: 14px;
			background-image: url('../../images/arrow-menu.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}

		&_open {
			&::after {
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}
}

.active_link {
	pointer-events: none;
	opacity: 0.7;
}

.phones_mobile {
	display: none;

	@media (max-width: $mobile-width) {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: $gray;
	}
}

.phone_mobile {
	text-decoration: none;
	color: $black;
	padding: 5px 0;

	&:hover {
		opacity: 0.7;
	}
}