@import '../../styles/variables.scss';

.container {
	background-color: #f3f6f4;
	border-radius: 15px;
	padding: 15px;
	display: grid;
	grid-template-columns: 1fr auto;
	transition: all 0.3s ease;

	&:hover {
		box-shadow: 0px 0px 10px 6px rgba(34, 60, 80, 0.1);
	}

	@media (max-width: $mobile-width) {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
	}
}

.doc {
	padding-left: 176px;
	padding-right: 176px;
	padding-top: 50px;
	padding-bottom: 100px;

	@media (max-width: $desktop-width) {
		padding-left: 44px;
		padding-right: 44px;
		padding-top: 30px;
		padding-bottom: 70px;
	}

	@media (max-width: $tablet-width) {
		padding-top: 10px;
		padding-bottom: 40px;
		padding-left: 5px;
		padding-right: 5px;
	}
}

.photo {
	width: 500px;
	height: auto;
	box-sizing: content-box;

	@media (max-width: $desktop-width) {
		width: 400px;
	}

	@media (max-width: $tablet-width) {
		width: 300px;
		align-self: center;
	}

	@media (max-width: $mobile-width) {
		width: 100%;
		max-width: 200px;
		margin-bottom: 20px;
	}
}

.description {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 30px;
	padding-right: 30px;

	@media (max-width: $mobile-width) {
		text-align: center;
		padding-left: 0;
		padding-right: 0;
	}
}

.title {
	font-family: $roboto-bold;
	font-size: 34px;
	line-height: 30px;
	margin-bottom: 30px;

	@media (max-width: $tablet-width) {
		font-size: 18px;
		line-height: 22px;
	}
}

.phones {
	display: flex;

	@media (max-width: $mobile-width) {
		justify-content: space-between;
	}
}

.phone {
	text-decoration: none;
	color: inherit;
	margin-right: 50px;
	font-size: 22px;
	transition: all 0.3s ease;

	@media (max-width: $tablet-width) {
		font-size: 18px;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		opacity: 0.8;
	}
}

.text {
	margin-top: 30px;

	@media (max-width: $tablet-width) {
		font-size: 14px;
		line-height: 20px;
	}
}

.button {
	text-transform: uppercase;
	text-decoration: none;
	padding: 12px 20px;
	border-radius: 20px;
	display: inline-block;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	width: 160px;
	text-align: center;
	margin-top: 30px;
	margin-bottom: 15px;
	box-sizing: content-box;
	transition: all 0.3s ease;
	background-color: $red;
	color: $white;
	border: 2px solid $red;

	&:hover {
		background-color: $white;
		color: $red;
	}

	@media (max-width: $tablet-width) {
		font-size: 14px;
		line-height: 16px;
		width: 120px;
		margin-bottom: 0;
		padding: 8px 10px;
		align-self: center;
	}

	@media (max-width: $mobile-width) {
		margin-top: 15px;
		margin-bottom: 5px;
	}
}