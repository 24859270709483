@import './variables.scss';

*,
*::before,
*::after {
	box-sizing: border-box;
}

img {
	max-width: 100%;
	height: auto;
}

body {
	min-width: 320px;
	// min-height: 100%;
	margin: 0;
	padding: 0;

	font-family: $main-font;
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
	color: #000000;
	min-height: 100%;
	overflow-x: hidden;
	position: relative;
}

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.active {
	pointer-events: none;
}

.ym-advanced-informer {
	display: flex;
	margin: 0 auto;
	margin-bottom: 20px;
	background-color: #000000;
}

iframe#webpack-dev-server-client-overlay {
	display: none !important
}