@import '../../styles/variables.scss';

.wrapper {
	display: grid;
	grid-template-rows: min-content 1fr min-content;
	margin: 0 auto;
	min-height: 100vh;
	grid-template-columns: 100%;
	min-width: 375px;
}

.image {
	position: relative;
	height: 600px;
	background-image: linear-gradient(to top, rgba(56, 40, 40, 0.3), rgba(56, 40, 40, 0.3)), url('../../images/gallery-12.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin-bottom: 170px;
}

.main_text {
	position: absolute;
	width: 1270px;
	left: 50%;
	bottom: -140px;
	transform: translateX(-50%);
	padding: 30px 70px;
	background-color: #f3f6f4;
	border-radius: 15px;

	@media (max-width: $desktop-width) {
		width: 90%;
	}

	@media (max-width: $tablet-width) {
		width: 90%;
		padding: 10px 30px;
	}
}

.title {
	font-size: 40px;
	line-height: 40px;
	font-family: $inter-bold;
	color: $blue-title;
	text-align: center;

	@media (max-width: $tablet-width) {
		font-size: 28px;
		line-height: 40px;
	}
}

.text {
	line-height: 26px;
}

.doc {
	padding-left: 176px;
	padding-right: 176px;
	padding-top: 50px;
	padding-bottom: 100px;

	@media (max-width: $desktop-width) {
		padding-left: 44px;
		padding-right: 44px;
		padding-top: 30px;
		padding-bottom: 70px;
	}

	@media (max-width: $tablet-width) {
		padding-top: 10px;
		padding-bottom: 40px;
		padding-left: 5px;
		padding-right: 5px;
	}
}

.container {
	background-color: #f3f6f4;
	border-radius: 15px;
	padding: 15px;
	display: grid;
	grid-template-columns: 1fr auto;
	transition: all 0.3s ease;

	&:hover {
		box-shadow: 0px 0px 10px 6px rgba(34, 60, 80, 0.1);
	}

	@media (max-width: $mobile-width) {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
	}
}

.photo {
	width: 500px;
	height: auto;
	box-sizing: content-box;

	@media (max-width: $desktop-width) {
		width: 400px;
	}

	@media (max-width: $tablet-width) {
		width: 300px;
		align-self: center;
	}

	@media (max-width: $mobile-width) {
		width: 100%;
		max-width: 400px;
		margin-bottom: 20px;
	}
}

.description {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 30px;

	@media (max-width: $mobile-width) {
		text-align: center;
		padding-left: 0;
	}
}

.name {
	font-family: $roboto-bold;
	font-size: 34px;
	line-height: 30px;
	margin-bottom: 10px;

	@media (max-width: $tablet-width) {
		font-size: 18px;
		line-height: 22px;
	}
}

.speciality {
	font-family: $roboto-bold;
	margin-bottom: 0;
}

.experience {
	font-family: $roboto-bold;
	margin-bottom: 0;
}

.diplomas {
	list-style: none;
	padding: 0;
	margin-top: 30px;
}

.diploma {
	margin-bottom: 10px;

	@media (max-width: $tablet-width) {
		font-size: 14px;
		line-height: 20px;
	}
}

.text {
	@media (max-width: $tablet-width) {
		font-size: 14px;
		line-height: 20px;
	}
}

.sub_text {
	margin-bottom: -10px;
}

.button {
	text-transform: uppercase;
	text-decoration: none;
	padding: 12px 20px;
	border-radius: 20px;
	display: inline-block;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	width: 160px;
	text-align: center;
	margin-top: 30px;
	margin-bottom: 15px;
	box-sizing: content-box;
	transition: all 0.3s ease;
	background-color: $red;
	color: $white;
	border: 2px solid $red;

	&:hover {
		background-color: $white;
		color: $red;
	}

	@media (max-width: $tablet-width) {
		font-size: 14px;
		line-height: 16px;
		width: 120px;
		margin-bottom: 0;
		padding: 8px 10px;
		align-self: center;
	}

	@media (max-width: $mobile-width) {
		margin-top: 15px;
		margin-bottom: 5px;
	}
}