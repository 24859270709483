@import '../../styles/variables.scss';

.wrapper {
	display: grid;
	grid-template-rows: min-content 1fr min-content;
	margin: 0 auto;
	min-height: 100vh;
	grid-template-columns: 100%;
	min-width: 375px;
}

.title {
	font-family: $inter-bold;
	font-size: 48px;
	line-height: 48px;
	text-transform: uppercase;
	text-align: center;

	@media (max-width: $desktop-width) {
		font-size: 40px;
		line-height: 40px;
	}

	@media (max-width: $tablet-width) {
		font-size: 35px;
		line-height: 35px;
	}

	@media (max-width: $mobile-width) {
		font-size: 28px;
		line-height: 28px;
	}
}