@import '../../styles/variables.scss';

.item {
	margin-top: 115px;
	padding-bottom: 30px;

	@media (max-width: $tablet-width) {
		padding-bottom: 90px;
		margin-top: 100px;
	}
}

.image {
	@media (max-width: $desktop-width) {
		width: 100%;
		height: auto;
	}
}

.title {
	font-family: $inter-bold;
	font-size: 24px;
	line-height: 33px;
	margin-top: 20px;
	height: 66px;

	@media (max-width: $tablet-width) {
		font-size: 20px;
		line-height: 25px;
		height: 50px;
	}
}

.text {
	margin-bottom: 23px;
}

.link {
	text-decoration: none;
	color: $red;
	position: relative;
	font-size: 18px;
	line-height: 18px;
	transition: all 0.3s ease;

	@media (max-width: $tablet-width) {
		font-size: 16px;
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: -55px;
		transform: translateY(-50%);
		width: 36px;
		height: 18px;
		background-image: url('../../images/arrow.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	&:hover {
		opacity: 0.7;
	}
}

.link_title {
	text-decoration: none;
	color: $black;
}