@import '../../styles/variables.scss';

.services {
	padding-left: 176px;
	padding-right: 176px;
	padding-top: 50px;

	@media (max-width: $desktop-width) {
		padding-left: 44px;
		padding-right: 44px;
	}

	@media (max-width: $tablet-width) {
		padding-left: 24px;
		padding-right: 24px;
	}
}

.title {
	font-family: $inter-bold;
	font-size: 48px;
	line-height: 48px;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: -68px;

	@media (max-width: $desktop-width) {
		font-size: 40px;
		line-height: 40px;
	}

	@media (max-width: $tablet-width) {
		font-size: 35px;
		line-height: 35px;
	}

	@media (max-width: $mobile-width) {
		font-size: 28px;
		line-height: 28px;
	}
}

:global(.swiper-pagination-bullet-active) {
	background-color: #f6f7ff;
	opacity: 1;
}

:global(.swiper-button-prev) {
	top: 7%;
	right: 94px;
	left: auto;
	outline: none;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 48px;
		height: 48px;
		background-image: url('../../images/nav-prev.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	@media (max-width: $tablet-width) {
		top: 95%;
		right: 55%;
	}

	@media (max-width: $mobile-width) {
		right: auto;
		left: 30%;
	}
}

:global(.swiper-button-next) {
	top: 7%;
	right: 22px;
	left: auto;
	outline: none;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 48px;
		height: 48px;
		background-image: url('../../images/nav-next.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	@media (max-width: $tablet-width) {
		top: 95%;
		right: 44%;
	}

	@media (max-width: $mobile-width) {
		left: auto;
		right: 36%;
	}
}