@import '../../styles/variables.scss';

.wrapper {
	display: grid;
	grid-template-rows: min-content 1fr min-content;
	margin: 0 auto;
	min-height: 100vh;
	grid-template-columns: 100%;
	min-width: 375px;
}

.contacts {
	padding-left: 176px;
	padding-right: 176px;
	padding-bottom: 50px;

	@media (max-width: $desktop-width) {
		padding-left: 44px;
		padding-right: 44px;
	}

	@media (max-width: $tablet-width) {
		padding-left: 24px;
		padding-right: 24px;
	}

	@media (max-width: $mobile-width) {
		padding-left: 5px;
		padding-right: 5px;
	}
}

.wrap {
	margin: 0 auto;
	width: 100%;
	margin-top: 50px;

	@media (max-width: $desktop-width) {
		width: 900px;
	}

	@media (max-width: $tablet-width) {
		width: 100%;
	}

	@media (max-width: $mobile-width) {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.contact {
	display: grid;
	grid-template-columns: 1fr 1fr;

	@media (max-width: $tablet-width) {
		grid-template-columns: 40% 60%;
	}

	@media (max-width: $mobile-width) {
		grid-template-columns: 1fr;
		justify-items: center;
	}
}


// .phones {
// 	justify-self: end;
// }

.link {
	position: relative;
	display: block;
	text-decoration: none;
	color: inherit;
	transition: all 0.3s ease;
	padding-left: 25px;
	margin-bottom: 15px;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 20px;
		height: 20px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	&:hover {
		opacity: 0.7;
	}
}

.phone {
	&::before {
		background-image: url('../../images/call.png');
	}
}

.vk {
	&::before {
		background-image: url('../../images/vk.png');
	}
}

.instagram {
	&::before {
		background-image: url('../../images/instagram.png');
	}
}

.whatsapp {
	&::before {
		background-image: url('../../images/whatsapp.png');
	}
}

.telegram {
	&::before {
		background-image: url('../../images/telegram.png');
	}
}